.client-list {
    padding: 1.5rem;
    background-color: #f8fafc;
    min-height: 100vh;
  
    // Header Styles
    .list-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
  
      .header-left {
        h1 {
          font-size: 1.5rem;
          font-weight: 600;
          color: #1e293b;
          margin-bottom: 0.25rem;
        }
  
        p {
          color: #64748b;
          font-size: 0.875rem;
        }
      }
  
      .header-actions {
        display: flex;
        gap: 1rem;
  
        .export-btn {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          padding: 0.75rem 1.25rem;
          background-color: #f1f5f9;
          color: #475569;
          border-radius: 8px;
          font-weight: 500;
          transition: all 0.2s ease;
  
          &:hover {
            background-color: #e2e8f0;
          }
        }
  
        .add-btn {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          padding: 0.75rem 1.25rem;
          background-color: #3b82f6;
          color: white;
          border-radius: 8px;
          font-weight: 500;
          transition: all 0.2s ease;
  
          &:hover {
            background-color: #2563eb;
          }
        }
      }
    }
  
    // Filters Section
    .list-filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5rem;
      gap: 1rem;
      padding: 1rem;
      background-color: white;
      border-radius: 12px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  
      .filters-left {
        display: flex;
        align-items: center;
        gap: 1rem;
        flex: 1;
  
        .search-bar {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          padding: 0.5rem 1rem;
          background-color: #f8fafc;
          border: 1px solid #e2e8f0;
          border-radius: 8px;
          min-width: 300px;
  
          svg {
            color: #94a3b8;
          }
  
          input {
            border: none;
            outline: none;
            width: 100%;
            font-size: 0.875rem;
            color: #1e293b;
            background-color: transparent;
  
            &::placeholder {
              color: #94a3b8;
            }
          }
        }
  
        .filter-group {
          display: flex;
          gap: 0.5rem;
  
          .filter-select {
            padding: 0.5rem 2rem 0.5rem 1rem;
            border: 1px solid #e2e8f0;
            border-radius: 8px;
            font-size: 0.875rem;
            color: #1e293b;
            background-color: white;
            cursor: pointer;
            min-width: 180px;
  
            &:focus {
              border-color: #3b82f6;
              box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
            }
          }
        }
      }
  
      .filters-right {
        display: flex;
        align-items: center;
        gap: 1rem;
  
        .sort-select {
          padding: 0.5rem 2rem 0.5rem 1rem;
          border: 1px solid #e2e8f0;
          border-radius: 8px;
          font-size: 0.875rem;
          color: #1e293b;
          background-color: white;
          cursor: pointer;
        }
  
        .view-switcher {
          display: flex;
          background-color: #f1f5f9;
          padding: 0.25rem;
          border-radius: 8px;
  
          .view-btn {
            padding: 0.5rem 1rem;
            font-size: 0.875rem;
            color: #64748b;
            border-radius: 6px;
            transition: all 0.2s ease;
  
            &.active {
              background-color: white;
              color: #1e293b;
              box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
            }
  
            &:hover:not(.active) {
              color: #1e293b;
            }
          }
        }
      }
    }
  
    // Grid View
    .client-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      gap: 1.5rem;
  
      .client-card {
        background-color: white;
        border-radius: 12px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        overflow: hidden;
  
        .card-header {
          padding: 1.25rem;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          border-bottom: 1px solid #e2e8f0;
  
          .client-info {
            display: flex;
            gap: 1rem;
            align-items: center;
  
            .client-avatar {
              width: 48px;
              height: 48px;
              border-radius: 12px;
              object-fit: cover;
            }
  
            h3 {
              font-size: 1rem;
              font-weight: 600;
              color: #1e293b;
              margin-bottom: 0.25rem;
            }
  
            .status-badge {
              display: inline-block;
              padding: 0.25rem 0.75rem;
              border-radius: 9999px;
              font-size: 0.75rem;
              font-weight: 500;
  
              &.active {
                background-color: #dcfce7;
                color: #15803d;
              }
  
              &.inactive {
                background-color: #f1f5f9;
                color: #475569;
              }
            }
          }
  
          .more-btn {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            color: #64748b;
            transition: all 0.2s ease;
  
            &:hover {
              background-color: #f1f5f9;
              color: #1e293b;
            }
          }
        }
  
        .card-content {
          padding: 1.25rem;
  
          .contact-info {
            margin-bottom: 1.25rem;
  
            .info-item {
              display: flex;
              align-items: center;
              gap: 0.75rem;
              margin-bottom: 0.75rem;
              font-size: 0.875rem;
              color: #64748b;
  
              &:last-child {
                margin-bottom: 0;
              }
  
              svg {
                color: #94a3b8;
                flex-shrink: 0;
              }
            }
          }
  
          .booking-info {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1rem;
            margin-bottom: 1.25rem;
            padding: 1rem;
            background-color: #f8fafc;
            border-radius: 8px;
  
            .stat-item {
              text-align: center;
  
              .label {
                display: block;
                font-size: 0.75rem;
                color: #64748b;
                margin-bottom: 0.25rem;
              }
  
              .value {
                font-size: 1rem;
                font-weight: 600;
                color: #1e293b;
              }
            }
          }
  
          .next-tour {
            margin-bottom: 1.25rem;
            padding: 1rem;
            background-color: #f0f9ff;
            border-radius: 8px;
  
            h4 {
              font-size: 0.875rem;
              font-weight: 600;
              color: #0369a1;
              margin-bottom: 0.75rem;
            }
  
            .tour-details {
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
  
              .tour-name {
                font-size: 0.875rem;
                color: #1e293b;
                font-weight: 500;
              }
  
              .tour-date {
                display: flex;
                align-items: center;
                gap: 0.375rem;
                font-size: 0.75rem;
                color: #64748b;
  
                svg {
                  color: #0369a1;
                }
              }
            }
          }
  
          .preferences {
            h4 {
              font-size: 0.875rem;
              font-weight: 600;
              color: #1e293b;
              margin-bottom: 0.75rem;
            }
  
            .preference-tags {
              display: flex;
              flex-wrap: wrap;
              gap: 0.5rem;
  
              .tag {
                padding: 0.25rem 0.75rem;
                background-color: #f1f5f9;
                color: #475569;
                border-radius: 9999px;
                font-size: 0.75rem;
              }
            }
          }
        }
  
        .card-actions {
          padding: 1.25rem;
          border-top: 1px solid #e2e8f0;
          display: flex;
          gap: 1rem;
  
          .action-btn {
            flex: 1;
            padding: 0.75rem;
            border-radius: 8px;
            font-size: 0.875rem;
            font-weight: 500;
            text-align: center;
            transition: all 0.2s ease;
  
            &:not(.primary) {
              background-color: #f1f5f9;
              color: #1e293b;
  
              &:hover {
                background-color: #e2e8f0;
              }
            }
  
            &.primary {
              background-color: #3b82f6;
              color: white;
  
              &:hover {
                background-color: #2563eb;
              }
            }
          }
        }
      }
    }
  
    // Table View
    .client-table {
      background-color: white;
      border-radius: 12px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      overflow: hidden;
  
      table {
        width: 100%;
        border-collapse: collapse;
  
        th {
          background-color: #f8fafc;
          padding: 1rem;
          text-align: left;
          font-size: 0.75rem;
          font-weight: 600;
          color: #64748b;
          text-transform: uppercase;
          letter-spacing: 0.05em;
        }
  
        td {
          padding: 1rem;
          font-size: 0.875rem;
          color: #475569;
          border-bottom: 1px solid #e2e8f0;
          vertical-align: middle;
        }
  
        tr {
          &:hover {
            background-color: #f8fafc;
          }
  
          &:last-child td {
            border-bottom: none;
          }
        }
  
        .client-cell {
          display: flex;
          align-items: center;
          gap: 0.75rem;
  
          img {
            width: 40px;
            height: 40px;
            border-radius: 8px;
            object-fit: cover;
          }
  
          div {
            display: flex;
            flex-direction: column;
  
            .name {
              font-weight: 500;
              color: #1e293b;
            }
  
            .email {
              font-size: 0.75rem;
              color: #64748b;
            }
          }
        }
  
        .next-tour-cell {
          display: flex;
          flex-direction: column;
          gap: 0.25rem;
  
          span {
            &.date {
              font-size: 0.75rem;
              color: #64748b;
            }
          }
        }
  
        .no-tour {
          color: #94a3b8;
          font-style: italic;
        }
  
        .action-buttons {
          display: flex;
          gap: 0.5rem;
  
          .icon-btn {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            color: #64748b;
            transition: all 0.2s ease;
  
            &:hover {
              background-color: #f1f5f9;
              color: #1e293b;
  
              &.delete {
                background-color: #fee2e2;
                color: #ef4444;
              }
            }
          }
        }
      }
    }
  
    // Responsive Design
    @media (max-width: 1024px) {
      .client-grid {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      }
  
      .client-table {
        overflow-x: auto;
  
        table {
          min-width: 1000px;
        }
      }
    }
  
    @media (max-width: 768px) {
      padding: 1rem;
  
      .list-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
  
        .header-actions {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
      }
  
      .list-filters {
        flex-direction: column;
  
        .filters-left {
          flex-direction: column;
          width: 100%;
  
          .search-bar {
            width: 100%;
            min-width: 0;
          }
  
          .filter-group {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
  
            .filter-select {
              min-width: 0;
            }
          }
        }
  
        .filters-right {
          width: 100%;
          justify-content: space-between;
        }
      }
  
      .client-grid {
        grid-template-columns: 1fr;
      }
    }
  
    @media (max-width: 480px) {
      .list-filters {
        .filters-left {
          .filter-group {
            grid-template-columns: 1fr;
          }
        }
  
        .filters-right {
          flex-direction: column;
          
          .view-switcher {
            width: 100%;
            justify-content: center;
          }
        }
      }
    }
  }