.crew-schedule {
  padding: 1.5rem;
  background-color: #f8fafc;
  min-height: 100vh;

  // Header Styles
  .schedule-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    .header-left {
      h1 {
        font-size: 1.5rem;
        font-weight: 600;
        color: #1e293b;
        margin-bottom: 0.25rem;
      }

      p {
        color: #64748b;
        font-size: 0.875rem;
      }
    }

    .header-actions {
      .new-schedule-btn {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.75rem 1.25rem;
        background-color: #3b82f6;
        color: white;
        border-radius: 8px;
        font-weight: 500;
        transition: all 0.2s ease;

        &:hover {
          background-color: #2563eb;
        }
      }
    }
  }

  // Filters Section
  .schedule-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    gap: 1rem;
    flex-wrap: wrap;

    .filters-left {
      display: flex;
      align-items: center;
      gap: 1rem;

      .view-switcher {
        display: flex;
        background-color: #f1f5f9;
        padding: 0.25rem;
        border-radius: 8px;

        .view-btn {
          padding: 0.5rem 1rem;
          font-size: 0.875rem;
          color: #64748b;
          border-radius: 6px;
          transition: all 0.2s ease;

          &.active {
            background-color: white;
            color: #1e293b;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
          }

          &:hover:not(.active) {
            color: #1e293b;
          }
        }
      }

      .period-selector {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        padding: 0.5rem;
        background-color: white;
        border: 1px solid #e2e8f0;
        border-radius: 8px;

        .nav-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 28px;
          height: 28px;
          border-radius: 6px;
          color: #64748b;
          transition: all 0.2s ease;

          &:hover {
            background-color: #f1f5f9;
            color: #1e293b;
          }
        }

        .current-period {
          font-size: 0.875rem;
          font-weight: 500;
          color: #1e293b;
          min-width: 120px;
          text-align: center;
        }
      }
    }

    .filters-right {
      display: flex;
      gap: 1rem;

      .filter-group {
        display: flex;
        gap: 0.5rem;

        .filter-select {
          padding: 0.5rem 2rem 0.5rem 1rem;
          border: 1px solid #e2e8f0;
          border-radius: 8px;
          font-size: 0.875rem;
          color: #1e293b;
          background-color: white;
          cursor: pointer;
          outline: none;
          appearance: none;
          background-image: url("data:image/svg+xml,...");
          background-repeat: no-repeat;
          background-position: right 0.75rem center;
          background-size: 16px;

          &:focus {
            border-color: #3b82f6;
            box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
          }
        }
      }

      .search-bar {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        background-color: white;
        border: 1px solid #e2e8f0;
        border-radius: 8px;
        min-width: 250px;

        svg {
          color: #94a3b8;
        }

        input {
          border: none;
          outline: none;
          width: 100%;
          font-size: 0.875rem;
          color: #1e293b;

          &::placeholder {
            color: #94a3b8;
          }
        }
      }
    }
  }

  // Schedule List View
  .schedule-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 1.5rem;

    .schedule-card {
      background-color: white;
      border-radius: 12px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      overflow: hidden;

      .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.25rem;
        border-bottom: 1px solid #e2e8f0;

        .crew-info {
          display: flex;
          align-items: center;
          gap: 1rem;

          .crew-avatar {
            width: 40px;
            height: 40px;
            border-radius: 8px;
            object-fit: cover;
          }

          h3 {
            font-size: 1rem;
            font-weight: 600;
            color: #1e293b;
            margin-bottom: 0.25rem;
          }

          .crew-role {
            font-size: 0.875rem;
            color: #64748b;
          }
        }

        .status-badge {
          padding: 0.375rem 0.75rem;
          border-radius: 9999px;
          font-size: 0.75rem;
          font-weight: 500;
        }
      }

      .card-content {
        padding: 1.25rem;

        .tour-details {
          margin-bottom: 1.25rem;

          h4 {
            font-size: 0.875rem;
            font-weight: 600;
            color: #1e293b;
            margin-bottom: 0.75rem;
          }

          .detail-item {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            font-size: 0.875rem;
            color: #64748b;
            margin-bottom: 0.5rem;

            &:last-child {
              margin-bottom: 0;
            }

            svg {
              color: #94a3b8;
              flex-shrink: 0;
            }
          }
        }

        .crew-details {
          padding-top: 1rem;
          border-top: 1px solid #e2e8f0;

          .languages {
            margin-bottom: 0.75rem;

            .label {
              font-size: 0.75rem;
              color: #64748b;
              display: block;
              margin-bottom: 0.5rem;
            }

            .language-tag {
              display: inline-flex;
              padding: 0.25rem 0.75rem;
              background-color: #f1f5f9;
              color: #475569;
              border-radius: 9999px;
              font-size: 0.75rem;
              margin-right: 0.5rem;
            }
          }

          .contact {
            .label {
              font-size: 0.75rem;
              color: #64748b;
              margin-right: 0.5rem;
            }

            span:last-child {
              font-size: 0.875rem;
              color: #1e293b;
            }
          }
        }
      }

      .card-actions {
        padding: 1.25rem;
        border-top: 1px solid #e2e8f0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .view-btn {
          padding: 0.5rem 1rem;
          background-color: #f1f5f9;
          color: #1e293b;
          border-radius: 6px;
          font-size: 0.875rem;
          font-weight: 500;
          transition: all 0.2s ease;

          &:hover {
            background-color: #e2e8f0;
          }
        }

        .more-btn {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          color: #64748b;
          transition: all 0.2s ease;

          &:hover {
            background-color: #f1f5f9;
            color: #1e293b;
          }
        }
      }
    }
  }

  // Calendar Views
  .schedule-calendar {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    &.month-view {
      // Month view specific styles
      // Will be added in next implementation
    }

    &.week-view {
      // Week view specific styles
      // Will be added in next implementation
    }
  }

  // Responsive Styles
  @media (max-width: 1024px) {
    .schedule-filters {
      .filters-left,
      .filters-right {
        width: 100%;
        justify-content: space-between;
      }
    }

    .schedule-list {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: 768px) {
    padding: 1rem;

    .schedule-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;

      .header-actions {
        width: 100%;

        .new-schedule-btn {
          width: 100%;
          justify-content: center;
        }
      }
    }

    .schedule-filters {
      flex-direction: column;
      gap: 1rem;

      .filters-left {
        flex-direction: column;
        gap: 1rem;

        .view-switcher {
          width: 100%;
          justify-content: center;
        }

        .period-selector {
          width: 100%;
          justify-content: center;
        }
      }

      .filters-right {
        flex-direction: column;

        .filter-group {
          width: 100%;

          .filter-select {
            flex: 1;
          }
        }

        .search-bar {
          width: 100%;
          min-width: 0;
        }
      }
    }
  }
}