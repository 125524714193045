.hotel-create {
    padding: 1.5rem;
    background-color: #f8fafc;
    min-height: 100vh;
  
    .form-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
  
      h1 {
        font-size: 1.5rem;
        font-weight: 600;
        color: #1e293b;
        margin-bottom: 0.25rem;
      }
  
      p {
        color: #64748b;
        font-size: 0.875rem;
      }
  
      .header-buttons {
        display: flex;
        gap: 1rem;
  
        button {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          padding: 0.75rem 1.25rem;
          border-radius: 8px;
          font-weight: 500;
          transition: all 0.2s ease;
  
          &.cancel-btn {
            background-color: #f1f5f9;
            color: #64748b;
  
            &:hover {
              background-color: #e2e8f0;
              color: #475569;
            }
          }
  
          &.save-btn {
            background-color: #3b82f6;
            color: white;
  
            &:hover {
              background-color: #2563eb;
            }
          }
        }
      }
    }
  
    .hotel-form {
      background-color: white;
      border-radius: 12px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      overflow: hidden;
    }
  
    .form-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
      padding: 2rem;
    }
  
    .form-section {
      margin-bottom: 2rem;
  
      h2 {
        font-size: 1rem;
        font-weight: 600;
        color: #1e293b;
        margin-bottom: 1rem;
      }
    }
  
    .form-group {
      margin-bottom: 1.5rem;
  
      label {
        display: block;
        font-size: 0.875rem;
        font-weight: 500;
        color: #1e293b;
        margin-bottom: 0.5rem;
  
        &.required::after {
          content: '*';
          color: #ef4444;
          margin-left: 0.25rem;
        }
      }
  
      input, select, textarea {
        width: 100%;
        padding: 0.75rem;
        border: 1px solid #e2e8f0;
        border-radius: 8px;
        font-size: 0.875rem;
        color: #1e293b;
        transition: all 0.2s ease;
  
        &:focus {
          outline: none;
          border-color: #3b82f6;
          box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
        }
  
        &.error {
          border-color: #ef4444;
        }
  
        &::placeholder {
          color: #94a3b8;
        }
      }
  
      textarea {
        resize: vertical;
        min-height: 100px;
      }
  
      .error-message {
        font-size: 0.75rem;
        color: #ef4444;
        margin-top: 0.25rem;
      }
    }
  
    .price-range {
      display: flex;
      align-items: center;
      gap: 1rem;
  
      input {
        flex: 1;
      }
  
      span {
        color: #64748b;
        font-size: 0.875rem;
      }
    }
  
    .images-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      gap: 1rem;
      margin-bottom: 1rem;
  
      .image-item {
        position: relative;
        aspect-ratio: 1;
        border-radius: 8px;
        overflow: hidden;
  
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
  
        .remove-image {
          position: absolute;
          top: 0.5rem;
          right: 0.5rem;
          width: 28px;
          height: 28px;
          background-color: rgba(255, 255, 255, 0.9);
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ef4444;
          transition: all 0.2s ease;
  
          &:hover {
            background-color: #ef4444;
            color: white;
          }
        }
      }
  
      .image-upload {
        aspect-ratio: 1;
        border: 2px dashed #e2e8f0;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease;
  
        .upload-placeholder {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0.5rem;
          color: #64748b;
  
          span {
            font-size: 0.875rem;
          }
        }
  
        &:hover {
          border-color: #3b82f6;
          color: #3b82f6;
  
          .upload-placeholder {
            color: #3b82f6;
          }
        }
      }
    }
  
    .amenities-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
      gap: 0.75rem;
  
      .amenity-checkbox {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        padding: 0.75rem;
        background-color: #f8fafc;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.2s ease;
  
        &:hover {
          background-color: #f1f5f9;
        }
  
        input[type="checkbox"] {
          width: 18px;
          height: 18px;
          border-radius: 4px;
          cursor: pointer;
          accent-color: #3b82f6;
        }
  
        span {
          font-size: 0.875rem;
          color: #475569;
        }
      }
    }
  
    .status-options {
      display: flex;
      gap: 1rem;
  
      .status-radio {
        flex: 1;
        display: flex;
        align-items: center;
        gap: 0.75rem;
        padding: 1rem;
        background-color: #f8fafc;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.2s ease;
  
        &:hover {
          background-color: #f1f5f9;
        }
  
        input[type="radio"] {
          width: 18px;
          height: 18px;
          cursor: pointer;
          accent-color: #3b82f6;
        }
  
        span {
          font-size: 0.875rem;
          color: #475569;
        }
      }
    }
  
    // Responsive styles
    @media (max-width: 1024px) {
      .form-grid {
        grid-template-columns: 1fr;
      }
  
      .images-grid {
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
      }
  
      .amenities-grid {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      }
    }
  
    @media (max-width: 768px) {
      padding: 1rem;
  
      .form-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
  
        .header-buttons {
          width: 100%;
          
          button {
            flex: 1;
            justify-content: center;
          }
        }
      }
  
      .form-grid {
        padding: 1rem;
      }
  
      .status-options {
        flex-direction: column;
      }
    }
  
    @media (max-width: 480px) {
      .images-grid {
        grid-template-columns: repeat(2, 1fr);
      }
  
      .amenities-grid {
        grid-template-columns: 1fr;
      }
  
      .price-range {
        flex-direction: column;
        gap: 0.5rem;
  
        input {
          width: 100%;
        }
      }
    }
  }
  
  // Loading states
  .form-loading {
    opacity: 0.7;
    pointer-events: none;
    position: relative;
  
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
  
  // Success and error messages
  .form-message {
    position: fixed;
    top: 1rem;
    right: 1rem;
    padding: 1rem;
    border-radius: 8px;
    animation: slideIn 0.3s ease;
    z-index: 100;
  
    &.success {
      background-color: #dcfce7;
      color: #15803d;
      border: 1px solid #86efac;
    }
  
    &.error {
      background-color: #fee2e2;
      color: #991b1b;
      border: 1px solid #fecaca;
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }