.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 64px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
    border-bottom: 1px solid #e5e7eb;
    z-index: 50;
  
    .navbar-left {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  
    .navbar-right {
      display: flex;
      align-items: center;
      gap: 1.5rem;
    }
  }
  
  .nav-icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: none;
    background: transparent;
    border-radius: 8px;
    color: #64748b;
    cursor: pointer;
    transition: all 0.2s ease;
  
    &:hover {
      background-color: #f1f5f9;
      color: #334155;
    }
  }
  
  .logo-container {
    display: flex;
    flex-direction: column;
    padding-left: 0.5rem;
  
    .logo-text {
      font-size: 1.25rem;
      font-weight: 700;
      color: #334155;
      line-height: 1.2;
      letter-spacing: 0.5px;
    }
  
    .logo-subtext {
      font-size: 0.75rem;
      color: #64748b;
      font-weight: 500;
    }
  }
  
  .notification-button {
    position: relative;
  
    .notification-badge {
      position: absolute;
      top: 6px;
      right: 6px;
      background-color: #f1f5f9;
      color: #64748b;
      font-size: 0.625rem;
      min-width: 16px;
      height: 16px;
      padding: 0 4px;
      border-radius: 8px;
      border: 2px solid #ffffff;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  
  .user-profile {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.5rem;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
  
    &:hover {
      background-color: #f1f5f9;
    }
  
    .user-avatar {
      width: 32px;
      height: 32px;
      border-radius: 8px;
      object-fit: cover;
    }
  
    .user-info {
      display: flex;
      flex-direction: column;
      line-height: 1.2;
  
      .user-name {
        font-size: 0.875rem;
        color: #334155;
        font-weight: 500;
      }
  
      .user-role {
        font-size: 0.75rem;
        color: #64748b;
      }
    }
  }
  
  // Responsive adjustments
  @media (max-width: 768px) {
    .logo-subtext {
      display: none;
    }
  
    .user-info {
      display: none !important;
    }
  }
  
  @media (max-width: 480px) {
    .navbar {
      padding: 0 1rem;
    }
  
    .logo-text {
      font-size: 1.125rem !important;
    }
  }