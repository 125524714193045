.tour-package-list {
  padding: 1.5rem;
  background-color: #f8fafc;
  min-height: 100vh;

  // Header Styles
  .list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    .header-left {
      h1 {
        font-size: 1.5rem;
        font-weight: 600;
        color: #1e293b;
        margin-bottom: 0.25rem;
      }

      p {
        color: #64748b;
        font-size: 0.875rem;
      }
    }

    .header-actions {
      .add-btn {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.75rem 1.25rem;
        background-color: #3b82f6;
        color: white;
        border-radius: 8px;
        font-weight: 500;
        transition: all 0.2s ease;

        &:hover {
          background-color: #2563eb;
        }
      }
    }
  }

  // Filters Section
  .list-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    gap: 1rem;
    padding: 1rem;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    .filters-left {
      display: flex;
      align-items: center;
      gap: 1rem;
      flex: 1;

      .search-bar {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        background-color: #f8fafc;
        border: 1px solid #e2e8f0;
        border-radius: 8px;
        min-width: 300px;

        svg {
          color: #94a3b8;
        }

        input {
          border: none;
          outline: none;
          width: 100%;
          font-size: 0.875rem;
          color: #1e293b;
          background-color: transparent;

          &::placeholder {
            color: #94a3b8;
          }
        }
      }

      .filter-group {
        .filter-select {
          padding: 0.5rem 2rem 0.5rem 1rem;
          border: 1px solid #e2e8f0;
          border-radius: 8px;
          font-size: 0.875rem;
          color: #1e293b;
          background-color: white;
          cursor: pointer;
          min-width: 160px;

          &:focus {
            border-color: #3b82f6;
            box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
          }
        }
      }
    }

    .filters-right {
      .view-switcher {
        display: flex;
        background-color: #f1f5f9;
        padding: 0.25rem;
        border-radius: 8px;

        .view-btn {
          padding: 0.5rem 1rem;
          font-size: 0.875rem;
          color: #64748b;
          border-radius: 6px;
          transition: all 0.2s ease;

          &.active {
            background-color: white;
            color: #1e293b;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
          }

          &:hover:not(.active) {
            color: #1e293b;
          }
        }
      }
    }
  }

  // Grid View
  .package-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    gap: 1.5rem;

    .package-card {
      background-color: white;
      border-radius: 12px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      overflow: hidden;

      .card-image {
        position: relative;
        height: 220px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .image-overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          padding: 1rem;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          .duration-badge {
            display: flex;
            align-items: center;
            gap: 0.375rem;
            padding: 0.375rem 0.75rem;
            background-color: rgba(255, 255, 255, 0.9);
            border-radius: 9999px;
            font-size: 0.75rem;
            font-weight: 500;
            color: #1e293b;

            svg {
              color: #3b82f6;
            }
          }

          .more-btn {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            background-color: rgba(255, 255, 255, 0.9);
            color: #475569;
            transition: all 0.2s ease;

            &:hover {
              background-color: white;
              color: #1e293b;
            }
          }
        }
      }

      .card-content {
        padding: 1.25rem;

        .package-info {
          margin-bottom: 1rem;

          h3 {
            font-size: 1.125rem;
            font-weight: 600;
            color: #1e293b;
            margin-bottom: 0.25rem;
          }

          .subtitle {
            color: #64748b;
            font-size: 0.875rem;
          }
        }

        .destinations {
          display: flex;
          align-items: center;
          gap: 0.375rem;
          margin-bottom: 1rem;
          padding: 0.75rem;
          background-color: #f8fafc;
          border-radius: 8px;

          span {
            font-size: 0.875rem;
            color: #1e293b;
          }

          .chevron {
            color: #94a3b8;
            flex-shrink: 0;
          }
        }

        .quick-info {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 0.75rem;
          margin-bottom: 1rem;

          .info-item {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            font-size: 0.813rem;
            color: #64748b;

            svg {
              color: #94a3b8;
              flex-shrink: 0;
            }
          }
        }

        .highlights {
          margin-bottom: 1rem;

          h4 {
            font-size: 0.875rem;
            font-weight: 600;
            color: #1e293b;
            margin-bottom: 0.75rem;
          }

          .highlight-tags {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;

            .highlight-tag {
              padding: 0.375rem 0.75rem;
              background-color: #f1f5f9;
              color: #475569;
              border-radius: 9999px;
              font-size: 0.75rem;
            }
          }
        }

        .inclusions {
          h4 {
            font-size: 0.875rem;
            font-weight: 600;
            color: #1e293b;
            margin-bottom: 0.75rem;
          }

          .inclusion-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 0.75rem;

            .inclusion-item {
              display: flex;
              align-items: center;
              gap: 0.5rem;
              font-size: 0.813rem;
              color: #64748b;

              svg {
                color: #10b981;
              }
            }
          }
        }
      }

      .card-footer {
        padding: 1.25rem;
        border-top: 1px solid #e2e8f0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .price-info {
          .price-range {
            margin-bottom: 0.25rem;

            .label {
              display: block;
              font-size: 0.75rem;
              color: #64748b;
              margin-bottom: 0.25rem;
            }

            .price {
              font-size: 1.25rem;
              font-weight: 600;
              color: #1e293b;
            }
          }

          .price-note {
            font-size: 0.75rem;
            color: #64748b;
          }
        }

        .action-buttons {
          display: flex;
          gap: 0.75rem;

          .action-btn {
            padding: 0.625rem 1rem;
            border-radius: 6px;
            font-size: 0.875rem;
            font-weight: 500;
            transition: all 0.2s ease;

            &:not(.primary) {
              background-color: #f1f5f9;
              color: #1e293b;

              &:hover {
                background-color: #e2e8f0;
              }
            }

            &.primary {
              background-color: #3b82f6;
              color: white;

              &:hover {
                background-color: #2563eb;
              }
            }
          }
        }
      }
    }
  }

  // Table View
  .package-table {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    table {
      width: 100%;
      border-collapse: collapse;

      th {
        background-color: #f8fafc;
        padding: 1rem;
        text-align: left;
        font-size: 0.75rem;
        font-weight: 600;
        color: #64748b;
        text-transform: uppercase;
        letter-spacing: 0.05em;
      }

      td {
        padding: 1rem;
        font-size: 0.875rem;
        color: #475569;
        border-bottom: 1px solid #e2e8f0;
      }

      .package-cell {
        display: flex;
        align-items: center;
        gap: 1rem;

        img {
          width: 80px;
          height: 60px;
          border-radius: 6px;
          object-fit: cover;
        }

        .name {
          display: block;
          font-weight: 500;
          color: #1e293b;
          margin-bottom: 0.25rem;
        }

        .subtitle {
          font-size: 0.75rem;
          color: #64748b;
        }
      }

      .duration-cell {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        svg {
          color: #94a3b8;
        }
      }

      .destinations-cell {
        display: flex;
        flex-wrap: wrap;
        gap: 0.375rem;

        .destination-tag {
          padding: 0.25rem 0.5rem;
          background-color: #f1f5f9;
          color: #475569;
          border-radius: 6px;
          font-size: 0.75rem;
        }
      }

      .price-cell {
        .price-ranges {
          display: flex;
          flex-direction: column;
          gap: 0.375rem;

          .price-range-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 0.813rem;

            .pax {
              color: #64748b;
            }

            .price {
              font-weight: 500;
              color: #1e293b;
            }
          }
        }
      }

      .action-buttons {
        display: flex;
        gap: 0.5rem;

        .icon-btn {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          color: #64748b;
          transition: all 0.2s ease;

          &:hover {
            background-color: #f1f5f9;
            color: #1e293b;
          }

          &.edit {
            color: #3b82f6;

            &:hover {
              background-color: #eff6ff;
            }
          }
        }
      }
    }
  }

  // Responsive Design
  @media (max-width: 1280px) {
    .package-grid {
      grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    }
  }

  @media (max-width: 1024px) {
    .package-table {
      overflow-x: auto;

      table {
        min-width: 1000px;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 1rem;

    .list-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;

      .header-actions {
        width: 100%;
        
        .add-btn {
          width: 100%;
          justify-content: center;
        }
      }
    }

    .list-filters {
      flex-direction: column;

      .filters-left {
        flex-direction: column;
        width: 100%;

        .search-bar {
          width: 100%;
          min-width: 0;
        }

        .filter-group {
          width: 100%;
        }
      }

      .filters-right {
        width: 100%;
        
        .view-switcher {
          width: 100%;
          justify-content: center;
        }
      }
    }

    .package-grid {
      grid-template-columns: 1fr;
    }
  }
}