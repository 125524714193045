.sidebar {
    position: fixed;
    left: 0;
    top: 64px;
    height: calc(100vh - 64px);
    background-color: #1a2236;
    color: white;
    transition: all 0.3s ease;
    width: 0;
    overflow: hidden;
    overflow-y: auto;
  
    &.open {
      width: 256px;
    }
  
    &::-webkit-scrollbar {
      width: 6px;
    }
  
    &::-webkit-scrollbar-track {
      background: #1a2236;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #2d3748;
      border-radius: 3px;
  
      &:hover {
        background-color: #4a5568;
      }
    }
  }
  
  .sidebar-content {
    padding: 0.5rem 0;
  }
  
  .menu-item {
    width: 100%;
    margin: 2px 0;
  }
  
  .menu-button,
  .menu-link {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.75rem 1rem;
    color: #a0aec0;
    transition: all 0.2s ease;
    text-decoration: none;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 0.875rem;
    position: relative;
  
    &:hover {
      background-color: #232d40;
      color: #e2e8f0;
    }
  
    &.active {
      background-color: #232d40;
      color: #60a5fa;
      font-weight: 500;
  
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 3px;
        background-color: #60a5fa;
      }
    }
  
    svg {
      color: #718096;
      min-width: 20px;
    }
  
    &:hover svg {
      color: #e2e8f0;
    }
  
    &.active svg {
      color: #60a5fa;
    }
  }
  
  .menu-button {
    justify-content: space-between;
    width: 100%;
  
    .menu-button-content {
      display: flex;
      align-items: center;
      gap: 0.75rem;
    }
  
    .menu-chevron {
      transition: transform 0.3s ease;
      opacity: 0.75;
      min-width: 16px;
  
      &.rotate {
        transform: rotate(-180deg);
      }
    }
  
    &.active {
      background-color: #232d40;
      
      .menu-chevron {
        color: #60a5fa;
      }
    }
  }
  
  .menu-link {
    gap: 0.75rem;
  }
  
  .submenu {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    background-color: #141c2f;
  
    &.expanded {
      max-height: 500px;
    }
  
    .submenu-link {
      display: flex;
      align-items: center;
      padding: 0.75rem 1rem 0.75rem 3.25rem;
      color: #a0aec0;
      text-decoration: none;
      transition: all 0.2s ease;
      font-size: 0.875rem;
  
      &:hover {
        background-color: #232d40;
        color: #e2e8f0;
      }
  
      &.active {
        background-color: #232d40;
        color: #60a5fa;
        font-weight: 500;
  
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 3px;
          background-color: #60a5fa;
        }
      }
    }
  }
  
  // Firefox scrollbar
  .sidebar {
    scrollbar-width: thin;
    scrollbar-color: #2d3748 #1a2236;
  }