.app {
  min-height: 100vh;
  background-color: #f1f5f9;
}

.main-content {
  padding-top: 64px; // Height of navbar
  min-height: calc(100vh - 64px);
  transition: all 0.3s ease;
  
  &.sidebar-expanded {
    margin-left: 256px; // Width of sidebar
  }
}

.page-container {
  padding: 1.5rem;
  max-width: 1920px;
  margin: 0 auto;
}

// Optional: Add loading state styles
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

// Optional: Add error state styles
.error {
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #fee2e2;
  color: #991b1b;
  margin: 1rem 0;
}

// Responsive adjustments
@media (max-width: 1024px) {
  .main-content {
    &.sidebar-expanded {
      margin-left: 0;
    }
  }
}

@media (max-width: 768px) {
  .page-container {
    padding: 1rem;
  }
}

// Base styles for all pages
.page-header {
  margin-bottom: 1.5rem;

  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1e293b;
  }

  .breadcrumbs {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    color: #64748b;
  }
}

// Card styles that can be used across pages
.card {
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 1.5rem;

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    h2 {
      font-size: 1.25rem;
      font-weight: 600;
      color: #1e293b;
    }
  }

  .card-body {
    color: #475569;
  }
}

// Grid layout utilities
.grid {
  display: grid;
  gap: 1.5rem;

  &.grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  &.grid-cols-3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &.grid-cols-4 {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1024px) {
    &.grid-cols-4 {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 768px) {
    &.grid-cols-3 {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 640px) {
    &.grid-cols-2,
    &.grid-cols-3,
    &.grid-cols-4 {
      grid-template-columns: 1fr;
    }
  }
}

// Animation utilities
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-in;
}