.crew-list {
    padding: 1.5rem;
    background-color: #f8fafc;
    min-height: 100vh;
  }
  
  .crew-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  
    .header-left {
      h1 {
        font-size: 1.5rem;
        font-weight: 600;
        color: #1e293b;
        margin-bottom: 0.25rem;
      }
  
      p {
        color: #64748b;
        font-size: 0.875rem;
      }
    }
  
    .add-crew-btn {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.75rem 1.25rem;
      background-color: #3b82f6;
      color: white;
      border-radius: 8px;
      font-weight: 500;
      transition: all 0.2s ease;
  
      &:hover {
        background-color: #2563eb;
      }
    }
  }
  
  .crew-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    gap: 1rem;
    flex-wrap: wrap;
  
    .search-bar {
      flex: 1;
      min-width: 280px;
      display: flex;
      align-items: center;
      background-color: white;
      border: 1px solid #e2e8f0;
      border-radius: 8px;
      padding: 0.5rem 1rem;
      gap: 0.5rem;
  
      input {
        flex: 1;
        border: none;
        outline: none;
        font-size: 0.875rem;
        color: #1e293b;
  
        &::placeholder {
          color: #94a3b8;
        }
      }
    }
  
    .filter-group {
      display: flex;
      gap: 0.5rem;
    }
  
    .filter-btn {
      padding: 0.5rem 1rem;
      border-radius: 6px;
      font-size: 0.875rem;
      color: #64748b;
      background-color: white;
      border: 1px solid #e2e8f0;
      transition: all 0.2s ease;
  
      &:hover {
        border-color: #94a3b8;
      }
  
      &.active {
        background-color: #3b82f6;
        color: white;
        border-color: #3b82f6;
      }
    }
  
    .view-toggle {
      display: flex;
      gap: 0.25rem;
      background-color: #f1f5f9;
      padding: 0.25rem;
      border-radius: 6px;
  
      .toggle-btn {
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
        border-radius: 4px;
        color: #64748b;
        transition: all 0.2s ease;
  
        &.active {
          background-color: white;
          color: #1e293b;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
  
  .crew-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 1.5rem;
  }
  
  .crew-card {
    background-color: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  
    .card-header {
      padding: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      border-bottom: 1px solid #e2e8f0;
  
      .crew-avatar {
        width: 64px;
        height: 64px;
        border-radius: 12px;
        object-fit: cover;
      }
  
      .more-btn {
        padding: 0.5rem;
        border-radius: 6px;
        color: #64748b;
  
        &:hover {
          background-color: #f1f5f9;
        }
      }
    }
  
    .card-body {
      padding: 1.5rem;
  
      .crew-info {
        margin-bottom: 1rem;
  
        h3 {
          font-size: 1.125rem;
          font-weight: 600;
          color: #1e293b;
          margin-bottom: 0.25rem;
        }
  
        .role {
          font-size: 0.875rem;
          color: #64748b;
          display: block;
          margin-bottom: 0.5rem;
        }
  
        .status {
          display: inline-block;
          padding: 0.25rem 0.75rem;
          border-radius: 9999px;
          font-size: 0.75rem;
          font-weight: 500;
        }
      }
  
      .crew-stats {
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;
  
        .stat {
          display: flex;
          align-items: center;
          gap: 0.375rem;
          color: #64748b;
          font-size: 0.875rem;
        }
      }
  
      .crew-details {
        margin-bottom: 1rem;
  
        .detail-item {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          color: #64748b;
          font-size: 0.875rem;
          margin-bottom: 0.5rem;
        }
      }
  
      .crew-languages,
      .crew-certifications {
        margin-bottom: 1rem;
  
        .detail-label {
          display: block;
          font-size: 0.75rem;
          color: #64748b;
          margin-bottom: 0.5rem;
        }
  
        .language-tags,
        .cert-tags {
          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem;
        }
  
        .language-tag,
        .cert-tag {
          padding: 0.25rem 0.75rem;
          background-color: #f1f5f9;
          color: #475569;
          border-radius: 9999px;
          font-size: 0.75rem;
        }
      }
    }
  
    .card-footer {
        padding: 1.5rem;
        border-top: 1px solid #e2e8f0;
        display: flex;
        gap: 1rem;
    
        button {
          flex: 1;
          padding: 0.75rem;
          border-radius: 8px;
          font-size: 0.875rem;
          font-weight: 500;
          transition: all 0.2s ease;
    
          &.view-profile-btn {
            background-color: #f1f5f9;
            color: #1e293b;
    
            &:hover {
              background-color: #e2e8f0;
            }
          }
    
          &.schedule-btn {
            background-color: #3b82f6;
            color: white;
    
            &:hover {
              background-color: #2563eb;
            }
          }
        }
      }
    }
    
    // Table View Styles
    .crew-table {
        background-color: white;
        border-radius: 12px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        
        table {
          width: 100%;
          border-collapse: collapse;
      
          th {
            background-color: #f8fafc;
            padding: 1rem;
            text-align: left;
            font-size: 0.75rem;
            font-weight: 600;
            color: #64748b;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            border-bottom: 1px solid #e2e8f0;
      
            &:first-child {
              padding-left: 1.5rem;
            }
      
            &:last-child {
              padding-right: 1.5rem;
            }
          }
      
          td {
            padding: 1rem;
            font-size: 0.875rem;
            color: #475569;
            border-bottom: 1px solid #e2e8f0;
            vertical-align: middle;
      
            &:first-child {
              padding-left: 1.5rem;
            }
      
            &:last-child {
              padding-right: 1.5rem;
            }
          }
      
          tr {
            &:hover {
              background-color: #f8fafc;
            }
          }
      
          .crew-name-cell {
            display: flex;
            align-items: center;
            gap: 0.75rem;
      
            img {
              width: 40px;
              height: 40px;
              border-radius: 8px;
              object-fit: cover;
            }
      
            div {
              display: flex;
              flex-direction: column;
      
              .name {
                font-weight: 500;
                color: #1e293b;
                margin-bottom: 0.25rem;
              }
      
              .email {
                font-size: 0.75rem;
                color: #64748b;
              }
            }
          }
      
          .status-badge {
            display: inline-flex;
            align-items: center;
            padding: 0.25rem 0.75rem;
            border-radius: 9999px;
            font-size: 0.75rem;
            font-weight: 500;
            text-transform: capitalize;
          }
      
          .rating {
            display: flex;
            align-items: center;
            gap: 0.375rem;
            color: #1e293b;
            font-weight: 500;
          }
      
          .languages {
            display: flex;
            gap: 0.5rem;
            flex-wrap: wrap;
      
            .language-tag {
              padding: 0.25rem 0.75rem;
              background-color: #f1f5f9;
              color: #475569;
              border-radius: 9999px;
              font-size: 0.75rem;
              white-space: nowrap;
            }
          }
      
          .action-buttons {
            display: flex;
            gap: 0.5rem;
      
            .action-btn {
              padding: 0.5rem 1rem;
              border-radius: 6px;
              font-size: 0.75rem;
              font-weight: 500;
              transition: all 0.2s ease;
      
              &.view-btn {
                background-color: #f1f5f9;
                color: #1e293b;
      
                &:hover {
                  background-color: #e2e8f0;
                }
              }
      
              &.edit-btn {
                background-color: #3b82f6;
                color: white;
      
                &:hover {
                  background-color: #2563eb;
                }
              }
            }
          }
        }
      
        // Responsive table
        @media (max-width: 1024px) {
          overflow-x: auto;
          
          table {
            min-width: 1000px;
          }
        }
      }
      
      // No results state
      .no-results {
        padding: 3rem;
        text-align: center;
        color: #64748b;
        background-color: white;
        border-radius: 12px;
      
        p {
          font-size: 0.875rem;
          margin-bottom: 1rem;
        }
      }    
    // Responsive Adjustments
    @media (max-width: 1280px) {
      .crew-filters {
        .filter-group {
          flex-wrap: wrap;
        }
      }
    }
    
    @media (max-width: 1024px) {
      .crew-grid {
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      }
    
      .crew-table {
        overflow-x: auto;
    
        table {
          min-width: 1000px;
        }
      }
    }
    
    @media (max-width: 768px) {
      .crew-list {
        padding: 1rem;
      }
    
      .crew-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    
        .add-crew-btn {
          width: 100%;
          justify-content: center;
        }
      }
    
      .crew-filters {
        flex-direction: column;
        align-items: stretch;
    
        .search-bar {
          width: 100%;
        }
    
        .filter-group,
        .view-toggle {
          width: 100%;
          justify-content: center;
        }
      }
    
      .crew-grid {
        grid-template-columns: 1fr;
      }
    }
    
    // Loading State
    .crew-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 200px;
      
      .loading-spinner {
        width: 40px;
        height: 40px;
        border: 3px solid #f1f5f9;
        border-top-color: #3b82f6;
        border-radius: 50%;
        animation: spin 1s linear infinite;
      }
    }
    
    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
    
    // Empty State
    .crew-empty {
      text-align: center;
      padding: 3rem;
      background-color: white;
      border-radius: 12px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    
      .empty-icon {
        color: #94a3b8;
        margin-bottom: 1rem;
      }
    
      h3 {
        font-size: 1.125rem;
        font-weight: 600;
        color: #1e293b;
        margin-bottom: 0.5rem;
      }
    
      p {
        color: #64748b;
        margin-bottom: 1.5rem;
      }
    
      button {
        padding: 0.75rem 1.5rem;
        background-color: #3b82f6;
        color: white;
        border-radius: 8px;
        font-weight: 500;
        transition: all 0.2s ease;
    
        &:hover {
          background-color: #2563eb;
        }
      }
    }
    .dropdown-container {
        position: relative;
      
        .dropdown-menu {
          position: absolute;
          top: 100%;
          right: 0;
          margin-top: 0.5rem;
          background-color: white;
          border-radius: 8px;
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
          min-width: 120px;
          z-index: 50;
          border: 1px solid #e2e8f0;
          
          .dropdown-item {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            width: 100%;
            padding: 0.75rem 1rem;
            color: #475569;
            font-size: 0.875rem;
            transition: all 0.2s ease;
      
            &:hover {
              background-color: #f8fafc;
              color: #1e293b;
            }
      
            svg {
              color: #64748b;
            }
      
            &:hover svg {
              color: #1e293b;
            }
          }
        }
      }
      
      .no-results {
        padding: 3rem;
        text-align: center;
        color: #64748b;
        background-color: white;
        border-radius: 12px;
      
        p {
          font-size: 0.875rem;
          margin-bottom: 1rem;
        }
      }