.create-crew {
    padding: 1.5rem;
    background-color: #f8fafc;
    min-height: 100vh;
  }
  
  .form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  
    h1 {
      font-size: 1.5rem;
      font-weight: 600;
      color: #1e293b;
      margin-bottom: 0.25rem;
    }
  
    p {
      color: #64748b;
      font-size: 0.875rem;
    }
  
    .header-buttons {
      display: flex;
      gap: 1rem;
  
      button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.75rem 1.25rem;
        border-radius: 8px;
        font-weight: 500;
        transition: all 0.2s ease;
  
        &.cancel-btn {
          background-color: #f1f5f9;
          color: #64748b;
  
          &:hover {
            background-color: #e2e8f0;
            color: #475569;
          }
        }
  
        &.save-btn {
          background-color: #3b82f6;
          color: white;
  
          &:hover {
            background-color: #2563eb;
          }
        }
      }
    }
  }
  
  .crew-form {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .form-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 2rem;
    padding: 2rem;
  }
  
  .form-section {
    margin-bottom: 2rem;
  
    h2 {
      font-size: 1rem;
      font-weight: 600;
      color: #1e293b;
      margin-bottom: 1rem;
    }
  }
  
  .upload-section {
    text-align: center;
    margin-bottom: 2rem;
  
    .avatar-upload {
      width: 150px;
      height: 150px;
      margin: 0 auto 1rem;
      position: relative;
      cursor: pointer;
  
      .avatar-preview {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        object-fit: cover;
      }
  
      .upload-placeholder {
        width: 100%;
        height: 100%;
        border: 2px dashed #e2e8f0;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        color: #64748b;
        transition: all 0.2s ease;
  
        &:hover {
          border-color: #3b82f6;
          color: #3b82f6;
        }
      }
  
      .file-input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
    }
  
    .upload-help {
      font-size: 0.875rem;
      color: #64748b;
    }
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  
    label {
      display: block;
      font-size: 0.875rem;
      font-weight: 500;
      color: #1e293b;
      margin-bottom: 0.5rem;
    }
  
    input, select {
      width: 100%;
      padding: 0.75rem;
      border: 1px solid #e2e8f0;
      border-radius: 8px;
      font-size: 0.875rem;
      color: #1e293b;
      transition: all 0.2s ease;
  
      &:focus {
        outline: none;
        border-color: #3b82f6;
        box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
      }
  
      &.error {
        border-color: #ef4444;
      }
    }
  
    .error-message {
      font-size: 0.75rem;
      color: #ef4444;
      margin-top: 0.25rem;
    }
  }
  
  .language-selection {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 0.75rem;
  
    .language-checkbox {
      display: flex;
      align-items: center;
      background-color: #f8fafc;
      padding: 0.75rem;
      border-radius: 8px;
      border: 1px solid #e2e8f0;
      cursor: pointer;
      transition: all 0.2s ease;
  
      &:hover {
        border-color: #94a3b8;
      }
  
      input[type="checkbox"] {
        width: 16px;
        height: 16px;
        border-radius: 4px;
        border: 2px solid #cbd5e1;
        margin-right: 0.75rem;
        cursor: pointer;
        accent-color: #3b82f6;
  
        &:checked {
          background-color: #3b82f6;
          border-color: #3b82f6;
        }
  
        &:focus {
          outline: 2px solid rgba(59, 130, 246, 0.5);
          outline-offset: 2px;
        }
      }
  
      span {
        font-size: 0.875rem;
        color: #475569;
        font-weight: 500;
        user-select: none;
      }
  
      &.selected {
        background-color: #eff6ff;
        border-color: #3b82f6;
  
        span {
          color: #1e40af;
        }
      }
  
      &:active {
        transform: scale(0.98);
      }
    }
  }
  
  // Error state for language section
  .language-section.error {
    .language-selection {
      border-radius: 8px;
      border: 1px solid #ef4444;
      padding: 0.5rem;
      background-color: #fef2f2;
    }
  
    .error-message {
      color: #ef4444;
      font-size: 0.75rem;
      margin-top: 0.5rem;
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }
  }
  
  // Responsive adjustments
  @media (max-width: 768px) {
    .language-selection {
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }
  }
  
  @media (max-width: 480px) {
    .language-selection {
      grid-template-columns: repeat(2, 1fr);
      
      .language-checkbox {
        padding: 0.625rem;
        
        input[type="checkbox"] {
          margin-right: 0.5rem;
        }
        
        span {
          font-size: 0.813rem;
        }
      }
    }
  }      

  .certification-input {
    margin-bottom: 1rem;
  
    input {
      width: 100%;
      padding: 0.75rem;
      border: 1px solid #e2e8f0;
      border-radius: 8px;
      font-size: 0.875rem;
      color: #1e293b;
      transition: all 0.2s ease;
  
      &:focus {
        outline: none;
        border-color: #3b82f6;
        box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
      }
  
      &::placeholder {
        color: #94a3b8;
      }
    }
  }
  
  .certification-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  
    .cert-tag {
      display: inline-flex;
      align-items: center;
      gap: 0.375rem;
      padding: 0.375rem 0.75rem;
      background-color: #f1f5f9;
      color: #475569;
      border-radius: 9999px;
      font-size: 0.875rem;
  
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: none;
        border: none;
        color: #64748b;
        cursor: pointer;
        padding: 0;
        font-size: 1rem;
        line-height: 1;
        transition: all 0.2s ease;
  
        &:hover {
          color: #ef4444;
          background-color: #fecaca;
        }
      }
    }
  }
  
  .status-selection {
    display: flex;
    gap: 1rem;
  
    .status-radio {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.75rem 1rem;
      border: 1px solid #e2e8f0;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.2s ease;
  
      &:hover {
        background-color: #f8fafc;
      }
  
      input[type="radio"] {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
  
      span {
        font-size: 0.875rem;
        color: #475569;
      }
    }
  }
  
  // Responsive styles
  @media (max-width: 1024px) {
    .form-grid {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  
    .form-right {
      padding-top: 1rem;
      border-top: 1px solid #e2e8f0;
    }
  
    .status-selection {
      flex-direction: column;
    }
  }
  
  @media (max-width: 768px) {
    .create-crew {
      padding: 1rem;
    }
  
    .form-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      margin-bottom: 1.5rem;
  
      .header-buttons {
        width: 100%;
        
        button {
          flex: 1;
          justify-content: center;
        }
      }
    }
  
    .form-grid {
      padding: 1rem;
    }
  
    .language-selection {
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }
  }
  
  // Loading state
  .form-loading {
    position: relative;
    opacity: 0.7;
    pointer-events: none;
  
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
  
  // Success and error states
  .form-feedback {
    position: fixed;
    top: 1rem;
    right: 1rem;
    padding: 1rem;
    border-radius: 8px;
    animation: slideIn 0.3s ease;
    z-index: 100;
  
    &.success {
      background-color: #dcfce7;
      color: #15803d;
      border: 1px solid #86efac;
    }
  
    &.error {
      background-color: #fee2e2;
      color: #991b1b;
      border: 1px solid #fecaca;
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  // Additional helper classes
  .required-field::after {
    content: '*';
    color: #ef4444;
    margin-left: 0.25rem;
  }
  
  .hint-text {
    font-size: 0.75rem;
    color: #64748b;
    margin-top: 0.25rem;
  }
  
  .validation-icon {
    position: absolute;
    right: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    
    &.valid {
      color: #10b981;
    }
    
    &.invalid {
      color: #ef4444;
    }
  }  