.transportation-list {
    padding: 1.5rem;
    background-color: #f8fafc;
    min-height: 100vh;
  
    .list-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
  
      .header-left {
        h1 {
          font-size: 1.5rem;
          font-weight: 600;
          color: #1e293b;
          margin-bottom: 0.25rem;
        }
  
        p {
          color: #64748b;
          font-size: 0.875rem;
        }
      }
  
      .add-btn {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.75rem 1.25rem;
        background-color: #3b82f6;
        color: white;
        border-radius: 8px;
        font-weight: 500;
        transition: all 0.2s ease;
  
        &:hover {
          background-color: #2563eb;
        }
      }
    }
  
    .filters-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5rem;
      gap: 1rem;
      flex-wrap: wrap;
  
      .search-bar {
        flex: 1;
        min-width: 300px;
        display: flex;
        align-items: center;
        background-color: white;
        border: 1px solid #e2e8f0;
        border-radius: 8px;
        padding: 0.5rem 1rem;
        gap: 0.5rem;
  
        svg {
          color: #94a3b8;
        }
  
        input {
          flex: 1;
          border: none;
          outline: none;
          font-size: 0.875rem;
          color: #1e293b;
  
          &::placeholder {
            color: #94a3b8;
          }
        }
      }
  
      .filter-buttons {
        display: flex;
        gap: 0.5rem;
  
        .filter-btn {
          padding: 0.5rem 1rem;
          border-radius: 6px;
          font-size: 0.875rem;
          color: #64748b;
          background-color: white;
          border: 1px solid #e2e8f0;
          transition: all 0.2s ease;
  
          &:hover {
            border-color: #94a3b8;
          }
  
          &.active {
            background-color: #3b82f6;
            color: white;
            border-color: #3b82f6;
          }
        }
      }
  
      .view-toggle {
        display: flex;
        gap: 0.25rem;
        background-color: #f1f5f9;
        padding: 0.25rem;
        border-radius: 6px;
  
        .toggle-btn {
          padding: 0.5rem 1rem;
          font-size: 0.875rem;
          border-radius: 4px;
          color: #64748b;
          transition: all 0.2s ease;
  
          &.active {
            background-color: white;
            color: #1e293b;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  
    .vehicles-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      gap: 1.5rem;
    }
  
    .vehicle-card {
      background-color: white;
      border-radius: 12px;
      overflow: hidden;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      transition: transform 0.2s ease;
  
      &:hover {
        transform: translateY(-2px);
      }
  
      .card-header {
        position: relative;
        height: 200px;
  
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
  
        .header-overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          padding: 1rem;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        }
  
        .more-btn {
          width: 32px;
          height: 32px;
          border-radius: 6px;
          background-color: rgba(255, 255, 255, 0.9);
          color: #475569;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.2s ease;
  
          &:hover {
            background-color: white;
            color: #1e293b;
          }
        }
      }
  
      .status-badge {
        padding: 0.25rem 0.75rem;
        border-radius: 9999px;
        font-size: 0.75rem;
        font-weight: 500;
      }
  
      .card-content {
        padding: 1.5rem;
  
        .vehicle-info {
          margin-bottom: 1.5rem;
  
          h3 {
            font-size: 1.125rem;
            font-weight: 600;
            color: #1e293b;
            margin-bottom: 0.25rem;
          }
  
          .plate-number {
            display: block;
            font-size: 0.875rem;
            color: #64748b;
            margin-bottom: 0.5rem;
          }
  
          .type-capacity {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            color: #64748b;
            font-size: 0.875rem;
  
            svg {
              color: #94a3b8;
            }
          }
        }
  
        .info-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 1rem;
          margin-bottom: 1.5rem;
          padding: 1rem;
          background-color: #f8fafc;
          border-radius: 8px;
  
          .info-item {
            text-align: center;
  
            label {
              display: block;
              font-size: 0.75rem;
              color: #64748b;
              margin-bottom: 0.25rem;
            }
  
            span {
              font-size: 0.875rem;
              color: #1e293b;
              font-weight: 500;
  
              &.warning {
                color: #f59e0b;
              }
  
              &.good {
                color: #10b981;
              }
            }
          }
        }
  
        .driver-info {
          margin-bottom: 1.5rem;
          padding: 1rem;
          border: 1px solid #e2e8f0;
          border-radius: 8px;
  
          h4 {
            font-size: 0.875rem;
            font-weight: 600;
            color: #1e293b;
            margin-bottom: 0.75rem;
          }
  
          .driver-details {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
  
            .name {
              display: block;
              font-size: 0.875rem;
              font-weight: 500;
              color: #1e293b;
              margin-bottom: 0.25rem;
            }
  
            .experience {
              font-size: 0.75rem;
              color: #64748b;
            }
  
            .phone {
              font-size: 0.813rem;
              color: #64748b;
            }
          }
        }
  
        .upcoming-trips {
          margin-bottom: 1.5rem;
  
          h4 {
            font-size: 0.875rem;
            font-weight: 600;
            color: #1e293b;
            margin-bottom: 0.75rem;
          }
  
          .trip-info {
            padding: 0.75rem;
            background-color: #f8fafc;
            border-radius: 8px;
  
            .trip-date {
              display: flex;
              align-items: center;
              gap: 0.5rem;
              color: #64748b;
              font-size: 0.813rem;
              margin-bottom: 0.5rem;
            }
  
            .trip-details {
              display: flex;
              justify-content: space-between;
              align-items: center;
  
              .tour {
                font-size: 0.875rem;
                font-weight: 500;
                color: #1e293b;
              }
  
              .duration {
                display: flex;
                align-items: center;
                gap: 0.25rem;
                font-size: 0.75rem;
                color: #64748b;
              }
            }
          }
        }
  
        .features {
          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem;
          margin-bottom: 1.5rem;
  
          .feature-tag {
            padding: 0.25rem 0.75rem;
            background-color: #f1f5f9;
            color: #475569;
            border-radius: 9999px;
            font-size: 0.75rem;
          }
        }
      }
  
      .card-footer {
        padding: 1rem 1.5rem;
        border-top: 1px solid #e2e8f0;
        display: flex;
        gap: 1rem;
  
        button {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          padding: 0.75rem;
          border-radius: 8px;
          font-size: 0.875rem;
          font-weight: 500;
          transition: all 0.2s ease;
  
          &.details-btn {
            background-color: #f1f5f9;
            color: #1e293b;
  
            &:hover {
              background-color: #e2e8f0;
            }
          }
  
          &.maintain-btn {
            background-color: #3b82f6;
            color: white;
  
            &:hover {
              background-color: #2563eb;
            }
          }
        }
      }
    }
  
    // Table Styles
    .vehicles-table {
      background-color: white;
      border-radius: 12px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      overflow: hidden;
  
      table {
        width: 100%;
        border-collapse: collapse;
  
        th {
          background-color: #f8fafc;
          padding: 1rem;
          text-align: left;
          font-size: 0.75rem;
          font-weight: 600;
          color: #64748b;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          border-bottom: 1px solid #e2e8f0;
        }
  
        td {
          padding: 1rem;
          border-bottom: 1px solid #e2e8f0;
          vertical-align: middle;
        }
  
        .vehicle-cell {
          display: flex;
          align-items: center;
          gap: 1rem;
  
          img {
            width: 48px;
            height: 48px;
            border-radius: 6px;
            object-fit: cover;
          }
  
          .name {
            display: block;
            font-weight: 500;
            color: #1e293b;
            margin-bottom: 0.25rem;
          }
  
          .details {
            font-size: 0.75rem;
            color: #64748b;
          }
        }
  
        .driver-cell {
          .name {
            display: block;
            font-weight: 500;
            color: #1e293b;
            margin-bottom: 0.25rem;
          }
  
          .phone {
            font-size: 0.75rem;
            color: #64748b;
          }
        }
  
        .maintenance-status {
          font-size: 0.875rem;
          font-weight: 500;
  
          &.warning {
            color: #f59e0b;
          }
  
          &.good {
            color: #10b981;
          }
        }
  
        .upcoming-cell {
          .tour {
            display: block;
            font-weight: 500;
            color: #1e293b;
            margin-bottom: 0.25rem;
          }
  
          .date {
            font-size: 0.75rem;
            color: #64748b;
          }
        }
  
        .no-trip {
          font-size: 0.875rem;
          color: #94a3b8;
        }
  
        .action-buttons {
          display: flex;
          gap: 0.5rem;
  
          .action-btn {
            padding: 0.5rem 1rem;
            border-radius: 6px;
            font-size: 0.75rem;
            font-weight: 500;
            transition: all 0.2s ease;
  
            &.view-btn {
              background-color: #f1f5f9;
              color: #1e293b;
  
              &:hover {
                background-color: #e2e8f0;
              }
            }
  
            &.edit-btn {
              background-color: #3b82f6;
              color: white;
  
              &:hover {
                background-color: #2563eb;
              }
            }
          }
        }
      }
    }
  
    // Responsive styles
    @media (max-width: 1024px) {
      .vehicles-grid {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      }
  
      .vehicles-table {
        overflow-x: auto;
  
        table {
          min-width: 1000px;
        }
      }
    }
  
    @media (max-width: 768px) {
      padding: 1rem;
  
      .list-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
  
        .add-btn {
          width: 100%;
          justify-content: center;
        }
      }
  
      .filters-section {
        flex-direction: column;
        align-items: stretch;
  
        .search-bar {
          width: 100%;
        }
  
        .filter-buttons,
        .view-toggle {
          justify-content: center;
        }
      }
  
      .vehicles-grid {
        grid-template-columns: 1fr;
      }
    }
  }