.resource-dashboard {
    padding: 1.5rem;
    background-color: #f8fafc;
    min-height: 100vh;
  
    .dashboard-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
  
      h1 {
        font-size: 1.5rem;
        font-weight: 600;
        color: #1e293b;
        margin-bottom: 0.25rem;
      }
  
      p {
        color: #64748b;
        font-size: 0.875rem;
      }
  
      .header-actions {
        display: flex;
        gap: 1rem;
  
        .date-filter {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          padding: 0.5rem 1rem;
          background-color: white;
          border: 1px solid #e2e8f0;
          border-radius: 8px;
  
          select {
            border: none;
            outline: none;
            color: #1e293b;
            font-size: 0.875rem;
            padding-right: 1rem;
            cursor: pointer;
          }
        }
  
        .search-bar {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          padding: 0.5rem 1rem;
          background-color: white;
          border: 1px solid #e2e8f0;
          border-radius: 8px;
          min-width: 300px;
  
          input {
            border: none;
            outline: none;
            width: 100%;
            font-size: 0.875rem;
            color: #1e293b;
  
            &::placeholder {
              color: #94a3b8;
            }
          }
        }
      }
    }
  
    .stats-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1.5rem;
      margin-bottom: 2rem;
  
      .stat-card {
        background-color: white;
        border-radius: 12px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        padding: 1.5rem;
  
        .card-header {
          display: flex;
          align-items: center;
          gap: 1rem;
          margin-bottom: 1.5rem;
  
          .header-icon {
            width: 48px;
            height: 48px;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            
            &.vehicle {
              background-color: #e0f2fe;
              color: #0284c7;
            }
  
            &.guide {
              background-color: #f0fdf4;
              color: #16a34a;
            }
  
            &.hotel {
              background-color: #fef3c7;
              color: #d97706;
            }
          }
  
          h3 {
            font-size: 1.125rem;
            font-weight: 600;
            color: #1e293b;
          }
        }
  
        .stats-content {
          display: flex;
          flex-direction: column;
          gap: 1rem;
  
          .stat-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 0.875rem;
  
            span:first-child {
              color: #64748b;
            }
  
            .number {
              font-weight: 600;
              color: #1e293b;
  
              &.available {
                color: #16a34a;
              }
  
              &.on-duty, &.booked {
                color: #0284c7;
              }
  
              &.maintenance {
                color: #f59e0b;
              }
  
              &.on-leave {
                color: #6366f1;
              }
            }
          }
        }
      }
    }
  
    .content-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.5rem;
  
      .content-card {
        background-color: white;
        border-radius: 12px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        overflow: hidden;
  
        &.alerts {
          grid-column: span 2;
        }
  
        .card-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1.25rem 1.5rem;
          border-bottom: 1px solid #e2e8f0;
  
          h3 {
            font-size: 1rem;
            font-weight: 600;
            color: #1e293b;
          }
  
          .view-all {
            font-size: 0.875rem;
            color: #3b82f6;
            font-weight: 500;
  
            &:hover {
              text-decoration: underline;
            }
          }
        }
  
        .card-content {
          padding: 1rem 1.5rem;
        }
      }
  
      // Upcoming Assignments
      .assignment-item {
        display: flex;
        gap: 1rem;
        padding: 1rem 0;
        border-bottom: 1px solid #e2e8f0;
  
        &:last-child {
          border-bottom: none;
        }
  
        .assignment-icon {
          width: 40px;
          height: 40px;
          border-radius: 8px;
          background-color: #f8fafc;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #64748b;
        }
  
        .assignment-info {
          flex: 1;
  
          h4 {
            font-size: 0.875rem;
            font-weight: 600;
            color: #1e293b;
            margin-bottom: 0.25rem;
          }
  
          p {
            font-size: 0.875rem;
            color: #64748b;
            margin-bottom: 0.5rem;
          }
  
          .assignment-details {
            display: flex;
            gap: 1rem;
            font-size: 0.75rem;
  
            span {
              display: flex;
              align-items: center;
              gap: 0.25rem;
              color: #64748b;
            }
  
            .status {
              &.confirmed {
                color: #16a34a;
              }
  
              &.pending {
                color: #f59e0b;
              }
            }
          }
        }
      }
  
      // Maintenance Schedule
      .maintenance-item {
        display: flex;
        gap: 1rem;
        padding: 1rem 0;
        border-bottom: 1px solid #e2e8f0;
  
        &:last-child {
          border-bottom: none;
        }
  
        .priority-indicator {
          width: 4px;
          border-radius: 2px;
  
          &.high {
            background-color: #ef4444;
          }
  
          &.normal {
            background-color: #f59e0b;
          }
  
          &.low {
            background-color: #22c55e;
          }
        }
  
        .maintenance-info {
          flex: 1;
  
          h4 {
            font-size: 0.875rem;
            font-weight: 600;
            color: #1e293b;
            margin-bottom: 0.25rem;
          }
  
          p {
            font-size: 0.875rem;
            color: #64748b;
            margin-bottom: 0.5rem;
          }
  
          .maintenance-details {
            display: flex;
            gap: 1rem;
            font-size: 0.75rem;
  
            span {
              display: flex;
              align-items: center;
              gap: 0.25rem;
              color: #64748b;
            }
          }
        }
      }
  
      // Alerts
      .alert-item {
        display: flex;
        gap: 1rem;
        padding: 1rem;
        border-radius: 8px;
        margin-bottom: 0.5rem;
  
        &:last-child {
          margin-bottom: 0;
        }
  
        &.high {
          background-color: #fef2f2;
          
          .alert-icon {
            color: #ef4444;
          }
        }
  
        &.warning {
          background-color: #fffbeb;
          
          .alert-icon {
            color: #f59e0b;
          }
        }
  
        &.info {
          background-color: #f0f9ff;
          
          .alert-icon {
            color: #0284c7;
          }
        }
  
        .alert-info {
          flex: 1;
  
          p {
            font-size: 0.875rem;
            color: #1e293b;
            margin-bottom: 0.25rem;
          }
  
          .alert-type {
            font-size: 0.75rem;
            color: #64748b;
          }
        }
      }
    }
  
    // Responsive styles
    @media (max-width: 1024px) {
      .stats-grid {
        grid-template-columns: repeat(2, 1fr);
      }
  
      .content-grid {
        grid-template-columns: 1fr;
  
        .content-card.alerts {
          grid-column: span 1;
        }
      }
    }
  
    @media (max-width: 768px) {
      padding: 1rem;
  
      .dashboard-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
  
        .header-actions {
          width: 100%;
          flex-direction: column;
  
          .search-bar {
            min-width: 100%;
          }
        }
      }
  
      .stats-grid {
        grid-template-columns: 1fr;
      }
    }
  }